import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Hisakazu KATO | WEB Developer', // e.g: 'Name | Developer'
  lang: 'jp', // e.g: en, es, fr, jp
  description: '(c)hiszuk design, full stack web developer', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'はじめまして! ',
  name: 'hiszuk です',
  subtitle: '➡️フルスタックエンジニア(修行中)😃',
  cta: 'ポートフォリオを見る',
};

// ABOUT DATA
export const aboutData = {
  img: 'me.jpg',
  paragraphOne: '現在某機械メーカーでIT技術者として働いています。レガシーシステムの改善を中心に、普段は小規模プロジェクトのプロマネ(たまに開発者)としてOracle/PLSQLで開発し、HP-UX上でシェルスクリプトで運用中です。',
  paragraphTwo:
    '世間のIT技術の進歩について行くために、WEBシステムのフロントエンド・バックエンド・CI/CDの勉強も兼ねて個人開発を始めました。フロントエンド: Vue(Nuxt), React(Next), element-ui, buefy, bulmaあたり。 バックエンド: spring boot。サーバーレス: firebase, netlify functions。他: Circle CI, Docker/Kubernetesがなんとか使える程度になりました。レガシー技術ならC,Awk,Perl,PL/SQLも使えます。',
  paragraphThree: '将来は要件定義〜フルスタックでデザインも開発もできるWEBエンジニアを目指して修行中です。よろしくお願いします。',
  resume: '', // if no resume(履歴書), the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'herbs.png',
    title: '健康診断データ管理アプリ',
    info: '複数医療機関から提供された社員の健康診断結果を標準書式に網変えて登録・管理する社内向けアプリ',
    info2:
      '右図のような構成で社内向けWEBアプリ開発で要件定義・設計・製造・マニュアル作成・運用環境構築の全てを担当。オンラインマニュアルはgatsbyを利用しています。EC2でのdocker環境構築は大きな学びとなりました。特に、運用費用節約のためEC2インスタンスを夜間に停止するため、起動停止時に自動でサービスを立ち上げ／終了する仕掛けをLINUXに組み込むノウハウを得ました。(2022-03-11 / フロントエンド:Next+TypeScript+MUI, バックエンド:SpringBoot, 動作環境: Docker on AWS/EC2)',
    url: '',
    repo: '',
  },
  {
    id: nanoid(),
    img: 'bootcamp.png',
    title: 'React Boot Camp',
    info: 'ユーザー登録・ビデオアップロード機能を持つ動画閲覧アプリ',
    info2:
      'React Boot Camp(https://github.com/Hiro-mackay/react-bootcamp/tree/bootcamp-1)でアプリ作成を学んだ後、視聴回数更新やチャンネル登録などの機能追加を独自で行いました。デモサイトのログインは初期表示されているユーザー名とパスワードのままでログイン可能です。一部更新系の機能制限をしています。(2021-10-10 / フロントエンド:React+TypeScript+MUI, バックエンド:HASURA+GraphQL, firebase)',
    url: 'https://react-bootcamp-351bc.web.app/',
    repo: 'https://github.com/hiszuk/react-bootcamp-advanced', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'photogallery.jpg',
    title: 'It Works!',
    info: 'Photo Gallery',
    info2: 'Gatsby.jsの勉強を兼ねて過去からの写真をBlog的に表示するサイトを作成しました。GitHubリポジトリはテンプレート化したものをリンクしています。(2021-01-17 / Gatsby.js+TypeScript+Tailwind CSS)',
    url: 'https://hiszuk-photo-gallery.netlify.app/',
    repo: 'https://github.com/hiszuk/gatsby-photoblog-with-tailwind', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'caferec.jpg',
    title: 'Cafe Rec',
    info: '社内向けアプリ',
    info2: '社員に毎日昼食時に食堂のどの席に座ったかをスマホで登録してもらい、もしコロナに感染した場合の影響範囲データをデスクトップ版から出力するソフト。登録画面はPWA対応済。ソース・サイトは一般非公開です。(2020-10-01 / Nuxt.js+Buefy+firebase)',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'sample-webap.jpg',
    title: 'Sample Webapp',
    info: '社内学習用サンプルアプリ',
    info2:
      'フロントエンド・バックエンド・DBをdocker-composeでパック化。それぞれを3回くらいに分けて講習会を開いた際のネタ。フロントエンドはNuxt/ElementUI、バックエンドはSpringBoot、DBにはMySQLを使い、それぞれをコンテナ化し最後はdocker-compose一発で起動できるようにまとめています。Liveはフロントエンドのみになります。',
    url: 'https://hiszuk-sample-webap-docker.netlify.app/items',
    repo: 'https://github.com/hiszuk/sample-nuxt-docker-app', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '修行の材料を探しています。何かございましたら、お気軽にお問い合わせください。',
  btn: 'お問い合わせ内容を登録する',
  email: 'hiszuk@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/hiszuk',
    },
    {
      id: nanoid(),
      name: 'link',
      url: 'https://zenn.dev/hiszuk',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/hiszuk/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
